import React, { Component } from 'react';

class TodoApp extends Component {

    state = {
        todos: [],
        newTodoName: ''
    };

    componentDidMount() {       
        this.getTodos();
    }

    getTodos() {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/todos`)
            .then(res => res.json())
            .then(todos => {
                this.setState({
                    todos: todos
                });
            });
    }

    handleNewTodoNameChange = (event) => {
        this.setState({ newTodoName: event.target.value });
    }

    addTodo = () => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/todos`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ task: this.state.newTodoName, completed: false })
        })
        .then(() => {
            this.getTodos();
            this.setState({ newTodoName: "" });
        });
    }

    deleteTodo = (id) => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/todos/${id}`, {
            method: 'DELETE'
        })
        .then(() => this.getTodos());
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          this.addTodo();
        }
      };

    toggleTodoCompletion = (id, status, task) => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/todos/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ task: task, completed: !status })
        })
        .then(() => this.getTodos());
    }

    render() {
        return (
            <div className="TodoApp center">
                <h1>Todo Application v3</h1>

                <table>
                    <thead>
                        <tr>
                            <th>Done</th>
                            <th>Task </th>
                            <th>Clear</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.todos.map(todo => (
                            <tr key={todo.id}>
                                <td>
                                    {/* Adjust the checkbox input by adding label and class for effect */}
                                    <input type="checkbox" checked={todo.completed} onChange={() => this.toggleTodoCompletion(todo.id, todo.completed,  todo.task)} className="sparkle" id={`todo-${todo.id}`} />
                                    <label htmlFor={`todo-${todo.id}`}></label>
                                </td>
                                <td className={todo.completed ? "completed" : ""}>
                                    {todo.task}
                                </td>
                                <td>
                                    <button onClick={() => this.deleteTodo(todo.id)} >Del</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <input type="text" value={this.state.newTodoName} onChange={this.handleNewTodoNameChange} onKeyPress={this.handleKeyPress} />
                <button onClick={this.addTodo}  style = {{marginTop: '3px'}} >Add Todo</button>
            </div>
        );
    }
}

export default TodoApp;